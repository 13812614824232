import "../../../../assets/css/token-popup.css";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { cryptoConfirmPayment, rejectAssetRequest } from "../../nft/services";
import useAlert from "../../../hooks/useAlert";
import { copyText } from "../../../utils/copy";

const BuyTokenPopUp = ({ data, wallet, hide, transferredByBank, resetForm }) => {
  const { showAlert } = useAlert();
  const [transId, setTransId] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = async () => {
    const info = {
      asset_request_id: data.assetRequestId,
    };
    const res = await rejectAssetRequest(info);
    hide();
    if (res.status) {
      showAlert("ERROR", "Alert", res.message);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (transId?.trim() === "") {
      setErrors({ transId: "Transaction id can not be empty" });
    } else {
      setErrors({});
      const info = {
        asset_request_id: data.assetRequestId,
        reference: transId,
      };
      const res = await cryptoConfirmPayment(info);
      if (res.status) {
        hide();
        resetForm();
        showAlert("SUCCESS", "Success", res.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col header ">Payment Process</div>
        </div>
        <div className="row ">
          <div className="col title">
            {
              transferredByBank ?
                <div className="d-flex">
                  Make sure to send payment of ${data.usdPrice}
                  <div
                    className="input-group-text cursor-pointer" style={{ marginLeft: "5px", marginRight: "5px" }}
                    onClick={() => {
                      copyText(data.usdPrice);
                      showAlert("SUCCESS", "Success", "Amount Copied");
                    }}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                  </div>
                  to receive {data.token} coins
                </div>
                :
                <div className="d-flex">
                  Make sure to send payment of {data.cryptoToken} {data.paymentCurrency}
                  <div
                    className="input-group-text cursor-pointer" style={{ marginLeft: "5px", marginRight: "5px" }}
                    onClick={() => {
                      copyText(data.cryptoToken);
                      showAlert("SUCCESS", "Success", "Amount Copied");
                    }}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                  </div>
                  to receive {data.token} coins
                </div>
            }
          </div>
        </div>

        {
          transferredByBank &&
          <div className="row buy-token-bank-detail">
            <div className="col-12 col-md-12">
              <label htmlFor="inputPassword4" className="form-label text-dark" style={{ fontWeight: 700 }}>
                Bank Detail:
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="inputPassword4" className="form-label text-dark">
                Bank Name: {data?.adminBankDetail?.bank_name}
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="inputPassword4" className="form-label text-dark">
                Account Number:  {data?.adminBankDetail?.account_number}
              </label>
            </div>
            <div className="col-12 col-md-6 mt-0">
              <label htmlFor="inputPassword4" className="form-label text-dark">
                Swift Code:  {data?.adminBankDetail?.swift_code}
              </label>
            </div>
          </div>
        }

        <div className="row">
          <div className="col subtitle">
            Send Amount: {transferredByBank ? `$${data.usdPrice}` : `${data.cryptoToken} ${data.paymentCurrency}`}
          </div>
        </div>
        {
          !transferredByBank &&
          <div className="row">
            <div className="col-md-9">
              <div className="input-group">
                <input
                  className="form-control bg-gray"
                  id="inputAddress"
                  disabled
                  value={data.adminWallet}
                />
                <div
                  className="input-group-text bg-gr cursor-pointer"
                  onClick={() => {
                    copyText(data.adminWallet);
                    showAlert("SUCCESS", "Success", "Address Copied");
                  }}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="row">
          <div className="col title">
            To speed up the verification process, please enter your transaction
            id or reference
          </div>
        </div>
        <div className="row">
          <div className="col subtitle">Transaction ID</div>
        </div>
        <div className="row">
          <div className="col">
            <input
              placeholder="Transaction ID"
              className="input-box"
              onChange={(e) => setTransId(e.target.value)}
              value={transId}
            />
          </div>
          {errors?.transId && (
            <p className="text-danger">
              <small>{errors?.transId}</small>
            </p>
          )}
        </div>
        {
          transferredByBank ?
            ""
            :
            <>
              <div className="row">
                <div className="col title">
                  Payment to the following {data.paymentCurrency} Wallet address
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <QRCode value={data.qrCode} size={180} />
                </div>
              </div>
            </>
        }
        <div className="row my-3 buy-token-action">
          <div className="col-md-12">
            {
              isLoading ?
                <button className="confirm-button" disabled>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </button>
                :
                <button className="confirm-button" onClick={handleSubmit}>
                  Confirm
                </button>
            }
            <button onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyTokenPopUp;
