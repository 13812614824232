/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import "tippy.js/dist/tippy.css";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import infoIcon from "../../../../assets/images/information.png";
import { createAssetRequest, getPriceToken } from "../../nft/services";
import { copyText } from "../../../utils/copy";
import useAlert from "../../../hooks/useAlert";
import BuyTokenPopUp from "./buyTokenPopup";
import OtherNFTProjects from "./otherNftProjects";
import { getWalletAddress } from "../../../utils/token";

const NFTFormCrypto = ({ data, ddshow }) => {
  const timeout = useRef();
  const { showAlert } = useAlert();
  const { masterData, selectedCrypto } = useSelector((state) => state.nft);

  const [isLoading, setIsLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [transferredByBank, setTransferredByBank] = useState(false);
  const [selected, setSelected] = useState(data.currencyToken[0]);
  const [values, setValues] = useState({});
  const [submitFormIsLoading, setSubmitFormIsLoading] = useState(false);
  const [selectedCryptos, setSelectedCryptos] = useState(selectedCrypto);

  // const baseURL = process.env.REACT_APP_BASE_URL;

  const isLoggedIn = localStorage.getItem("user_name");
  const isKycDone = localStorage.getItem("kyc_done");

  useEffect(() => {
    if (data.token !== "" && isLoading === true) {
      setIsLoading(false);
    }
  }, []);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const getTokenPrice = async (obj) => {
    const tokenDetail = {
      crypto_type_id: obj ? obj.id : selected.id,
      crypto_token_id: data.token.id,
      crypto_name: obj ? obj.name : selected.name,
      token: formik.values.token ? parseFloat(formik.values.token, 10) : 0,
      crypto_amount: 0,
    };

    const result = await getPriceToken(tokenDetail);
    if (result.status) {
      formik.setFieldValue("token", result.token.toString());
      formik.setFieldValue("cryptoToken", result.crypto_amount.toString());
      formik.setFieldValue("usdPrice", result.usd_price);
    } else {
      formik.setFieldValue("token", "");
      formik.setFieldValue("cryptoToken", "");
      formik.setFieldValue("usdPrice", 0);
    }
    setCheck(true);
  };

  const handleTokenInput = () => {
    // Clear the previous timeout.
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      getTokenPrice();
    }, 600);
  };

  const handelAssetRequest = async (values) => {
    setSubmitFormIsLoading(true);
    if (isKycDone === true || isKycDone === "true") {
      const userBankDetail = JSON.parse(localStorage.getItem("user_bank_details"));
      const walletAddress = await getWalletAddress();

      if ((!userBankDetail?.account_number || userBankDetail?.account_number === "") && (!walletAddress || walletAddress === "")) {
        showAlert("ERROR", "Error", "Please update Wallet Address and Bank Detail for this user.");
      } else if (!walletAddress || walletAddress === "") {
        showAlert("ERROR", "Error", "Please update Wallet Address for this user.");
      } else if (!userBankDetail?.account_number || userBankDetail?.account_number === "") {
        showAlert("ERROR", "Error", "Please update Bank Detail for this user.");
      } else {
        const typeMaster = masterData.crypto_tokens;
        const typeToken = typeMaster?.filter(ele => ele?.id === selectedCrypto)[0]?.geofancing;
        let geoFancingId = [];

        typeToken && typeToken.map((val) => { geoFancingId.push({ nft_id: val.id }) });

        const cryptoDetail = {
          crypto_token_id: data.token.id,
          crypto_type_id: selected.id,
          // crypto_amount: parseFloat(values.cryptoToken, 10),
          currency_id: masterData.exchange_data[0]?.supported_fiat_currencies[0]?.id,
          token: parseFloat(values.token, 10),
          nft_details: geoFancingId,
        };

        if (transferredByBank) {
          cryptoDetail.bank_transfer_amount = values.usdPrice;
          cryptoDetail.transaction_type = "bank_transfer";
          cryptoDetail.transaction_id = values.transaction_id;
        } else {
          cryptoDetail.crypto_amount = parseFloat(values.cryptoToken, 10);
          cryptoDetail.transaction_type = "crypto";
        }

        const res = await createAssetRequest(cryptoDetail);

        if (res.status) {
          setValues({
            assetRequestId: res.asset_request_id,
            paymentCurrency: res.payment_currency,
            qrCode: res.crypto_address,
            adminWallet: res.crypto_address,
            usdPrice: values.usdPrice,
            token: values.token,
            cryptoToken: values.cryptoToken,
            adminBankDetail: masterData?.admin_bank_details
          });
          showModal();

        } else {
          showAlert("ERROR", "Error", res.message);
        }
      }
    } else {
      showAlert("ERROR", "Error", "Your KYC process not complete.");
      // navigate("/kyc-verification");
      window.location.href = process.env.REACT_APP_KYC_VERIFICATION_URL;
    }
    setSubmitFormIsLoading(false);
  };

  useEffect(() => {
    let a;
    if (check) {
      a = setTimeout(() => {
        getTokenPrice();
      }, masterData.exchange_data[0].tsp_price_valid_in_minutes);
    }
    return () => {
      clearTimeout(a);
    };
  }, [selected]);

  const handleSelectChange = (e) => {
    const obj = JSON.parse(e.target.value);
    setSelected(obj);
    getTokenPrice(obj);
  };

  const message = "On successful transaction, coins will be transferred to this account";

  const initialValues = {
    token: "",
    cryptoToken: "",
  };

  const formSchema = Yup.object().shape({
    token: Yup.string()
      .trim()
      .required("Token cannot not be empty"),
    cryptoToken: Yup.string()
      .trim()
      .required("Crypto token cannot not be empty"),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await handelAssetRequest(values);
    },
  });

  const resetForm = () => {
    setTransferredByBank(false);
    formik.resetForm();
  }

  return (
    <>
      <div className="col-12 col-lg-6 mb-4 mb-lg-0">
        <div className="form-box">
          {isLoading === false ? (
            <>
              <form className="row g-3" onSubmit={formik.handleSubmit}>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label text-dark">Your Wallet Address</label>
                  <Tippy content={message}>
                    <img src={infoIcon} alt="" className="info-icon" />
                  </Tippy>
                  <div className="input-group">
                    <input className="form-control " id="inputAddress" value={data?.walletAddress && data?.walletAddress !== "false" ? data.walletAddress : ""} disabled />
                    <div
                      className="input-group-text cursor-pointer"
                      onClick={() => {
                        copyText(data.walletAddress); showAlert("SUCCESS", "Success", "Address Copied");
                      }}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-7">
                  <label htmlFor="inputEmail4" className="form-label text-dark">No Of Token</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      name="token"
                      onKeyUp={handleTokenInput}
                      {...formik.getFieldProps("token")}
                    />
                  </div>
                  {formik.touched.token && formik.errors.token && (
                    <p className="text-danger">
                      <small>{formik.errors.token}</small>
                    </p>
                  )}
                  <p> USD price: ${formik?.values?.usdPrice ? formik.values.usdPrice : "0"} </p>
                </div>
                {ddshow ? (
                  <div className="col-12 col-md-5">
                    <label htmlFor="inputPassword4" className="form-label text-dark">
                      Project Name
                    </label>
                    <OtherNFTProjects image={false} />
                  </div>
                ) : (
                  ""
                )}

                {
                  formik.values.usdPrice && formik.values.usdPrice > masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit ?
                    <>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <input type="radio" name="transferredByCrypro" checked={!transferredByBank} onChange={() => setTransferredByBank(false)} />
                          <label htmlFor="inputEmail4" className="text-dark mx-1">
                            Transferred Via Crypto
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <input type="radio" name="transferredByBank" checked={transferredByBank} onChange={() => setTransferredByBank(true)} />
                          <label htmlFor="inputEmail4" className="text-dark mx-1">
                            Transferred Via Bank
                          </label>
                        </div>
                      </div>
                    </>
                    : ""
                }

                {
                  transferredByBank ?
                    <>
                      <div className="col-12 col-md-6">
                        <label htmlFor="inputPassword4" className="form-label text-dark">
                          Bank Name: {masterData?.admin_bank_details?.bank_name}
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <label htmlFor="inputPassword4" className="form-label text-dark">
                          Account Number:  {masterData?.admin_bank_details?.account_number}
                        </label>
                      </div>
                      <div className="col-12 col-md-6 mt-0">
                        <label htmlFor="inputPassword4" className="form-label text-dark">
                          Swift Code:  {masterData?.admin_bank_details?.swift_code}
                        </label>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-12 col-md-7">
                        <label htmlFor="inputEmail4" className="form-label text-dark">Pay</label>
                        <div className="input-group">
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            id="inputEmail4"
                            name="cryptoToken"
                            {...formik.getFieldProps("cryptoToken")}
                          />
                          {/* <div className="input-group-text bg-gr">
                            <img aria-hidden="true" height={50} src={baseURL + selected.img_src} alt="" />
                          </div> */}
                        </div>
                        <p>Minimum token to purchase is 1</p>
                        {formik.touched.cryptoToken && formik.errors.cryptoToken && (
                          <p className="text-danger">
                            <small>{formik.errors.cryptoToken}</small>
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-5">
                        <label htmlFor="inputPassword4" className="form-label text-dark">
                          Currency
                        </label>
                        <select className="form-select" onChange={handleSelectChange}>
                          {data.currencyToken &&
                            data.currencyToken.map((val, index) => (
                              <option value={JSON.stringify(val)} key={index}>
                                {val.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </>
                }

                <div className="col-12">
                  <div className="info-box">
                    <div className="info-img">
                      <img src={infoIcon} alt="" />
                    </div>
                    <div className="info-content">
                      <p>
                        {
                          data?.exchangeData.tsp_buy_screen_promotional_text
                            ? data.exchangeData.tsp_buy_screen_promotional_text
                            : "Your total cost will be calculated based on the exchange rate at the moment when your transaction is confirmed Header"
                        }
                      </p>
                      <p>
                        {
                          data?.exchangeData.tsp_buy_screen_footer_text
                            ? data.exchangeData.tsp_buy_screen_footer_text
                            : "The Solar coins will appear in your account after the successful receipt of payment and approval by our team. Footer. The current price is valid for 3 minutes"
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {
                    submitFormIsLoading ?
                      <button type="submit" className="btn mx-auto" style={{ backgroundColor: "#186200", borderColor: "#186200" }} disabled>
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                      :
                      <button type="submit" className="btn btn-darkgreen mx-auto">
                        {isLoggedIn ? "Buy Tokens" : "Login"}
                      </button>
                  }
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal size="lg" show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <BuyTokenPopUp data={values} wallet={data.walletAddress} hide={hideModal} transferredByBank={transferredByBank} resetForm={resetForm} selectedCryptos={selectedCryptos} setSelectedCryptos={setSelectedCryptos} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NFTFormCrypto;
