/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import "../../../assets/css/footer-bottom.css";
import React, { useEffect, useState } from "react";
import sunsetCapitalLogo from "../../../assets/images/sunset_capital_logo.png";
import { getFooterData } from "../../../app/pages/home/services/home";

const FooterBottom = ({ pageName }) => {
  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);
  const [socialMedia, setSocialMedia] = useState("");
  const [copyRight, setCopyRight] = useState("");

  const fetchFooterData = async () => {
    let type;
    if (pageName === "nature" || pageName === "nature-project" || pageName === "nature-project-list") {
      type = "nature";
    } else if (pageName === "energy" || pageName === "energy-project" || pageName === "energy-project-list") {
      type = "energy";
    } else {
      type = "corporate";
    }

    const result = await getFooterData(type);
    if (result && result.success_msg === "Successfully") {
      setColumn1(result.column1);
      setColumn2(result.column2);
      setColumn3(result.column3);
      setSocialMedia(result.social_media_dict);
      setCopyRight(result.copyright);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);

  return (
    <div className="footer-bottom-main">
      <div className="row d-flex">
        <div className="col-12 col-md-3 col-lg-3">
          <div className="d-flex mb-4 flex-column align-items-start text-center text-md-start">
            <img src={sunsetCapitalLogo} className="footer-logo" alt="" />
            <p className="footer-summury">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              tristique diam lacus, nec ullamcorper massa faucibus vitae.
              Praesent at bibendum tortor.
            </p>
            <button className="btn button-green">Discover More</button>
          </div>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-3 pt-md-3 text-start d-flex justify-content-center text-center text-md-start">
          <div className="flex-0 text-center text-md-start side-border">
            {column1 && column1.length > 0 && (
              <h2 className="footer-column-title">{column1[0]?.main_head}</h2>
            )}
            {column1 &&
              column1.map((data, index) => {
                return (
                  <p className="font-14 font-weight-bold" key={index}>
                    {data.page_type_name === "contact" ? (
                      <a href={`/contact-us`}>{data.display_name}</a>
                    ) : (
                      <a href={`/cms-page/${data.page_type_name}`}>
                        {data.display_name}
                      </a>
                    )}
                  </p>
                );
              })}
          </div>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-3 pt-md-3 text-start d-flex justify-content-center text-center text-md-start">
          <div className="flex-0 text-center text-md-start side-border">
            {column2 && column2.length > 0 && (
              <h2 className="footer-column-title">{column2[0].main_head}</h2>
            )}
            {column2 &&
              column2.map((data, index) => {
                return (
                  <p className="font-14 font-weight-bold" key={index}>
                    {data.page_type_name === "contact" ? (
                      <a href={`/contact-us`}>{data.display_name}</a>
                    ) : (
                      <a href={`/cms-page/${data.page_type_name}`}>
                        {data.display_name}
                      </a>
                    )}
                  </p>
                );
              })}
          </div>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-3 pt-md-3 d-flex justify-content-center justify-content-md-end text-center text-md-start">
          <div className="flex-0 text-center text-md-start side-border">
            {column3 && column3.length > 0 && (
              <h2 className="footer-column-title">{column3[0].main_head}</h2>
            )}
            {column3 &&
              column3.map((data, index) => {
                return data.display_name === "divider" ? (
                  <br />
                ) : (
                  <p className="font-14" key={index}>
                    <span
                      style={{
                        width: "22px",
                        height: "22px",
                        display: "inline-block",
                        marginRight: "5px",
                      }}
                    >
                      <i
                        className={`fab fa fa-${data.display_name.split("_")[1]
                          }`}
                      ></i>
                    </span>
                    {data.display_name.split("_")[0]}
                  </p>
                );
              })}
          </div>
        </div>
      </div>

      <div className="row d-sm-none d-md-flex mt-5">
        <div className="col-12 col-sm-7 col-md-6 col-lg-6 copy-right-desktop">
          <p className="font-12 font-weight-normal text-start">{copyRight}</p>
        </div>
        <div className="col-12 col-sm-5 col-md-6 col-lg-6 footer-social">
          <div className="row justify-content-end">
            {socialMedia.social_linkedin && (
              <div className="col-1">
                <a href={socialMedia.social_linkedin} target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            )}
            {socialMedia.social_facebook && (
              <div className="col-1">
                <a href={socialMedia.social_facebook} target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
              </div>
            )}
            {socialMedia.social_twitter && (
              <div className="col-1">
                <a href={socialMedia.social_twitter} target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            )}
            {socialMedia.social_instagram && (
              <div className="col-1">
                <a href={socialMedia.social_instagram} target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            )}
            {socialMedia.social_youtube && (
              <div className="col-1">
                <a href={socialMedia.social_youtube} target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
